import { createContext } from "react";
import { ImagesDTO } from "../DTOs/ImagesDTO";

type ImageContextType = {
    images: ImagesDTO;
    alterarLogo: (path: string) => void,

};


export const imageInitial = {
        Logo: "../../images/Logo/logo.jpg"
};

const imageContext = createContext<ImageContextType>({images: imageInitial, alterarLogo: (path: string) => {}});



export default imageContext;
