import { Link } from "react-router-dom";
import styled from "styled-components";
import './../Thema/index.css';

export const Container = styled.header`
  height: 80px;
  top: 0px;
  background-color: var(--headerBackground-bg-color);
  color: #fff;
  left: 0;
  right: 0;
  padding: 15px 10px;
  transition: all 0.3s ease-out;

  @media(max-width: 923px){
    height: 100px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin: 10px 30px 20px 15px;
  justify-content: space-around;

  @media (min-width: 426px) {
    text-align: center;
  }

  @media (min-width: 920px) {
    align-items: center;
  }
`;

export const CabecalhoLogo = styled.div`
  width: 100%;

  @media (max-width: 923px) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CabecalhoLogoImagem = styled.div`
  padding: 0;
  margin: 0;
  position: inherit;
  left: 0;
  width: 100%;
  cursor: pointer;
  max-width: 200px;

  
  /* .btn-shop{
  display: inline-flex;
  align-items: center;
  text-transform: none;
} */
.iconCart{
  display: inline-flex;
    align-items: center;
}

.infos-cart{
  display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 5px;
    text-align: initial;
}


  /* @media (max-width: 768px) {
    position: inherit;
    left: 0;
    width: 100%;
    cursor: pointer;
    max-width: 130px;
  }

  @media (min-width: 769px) {
    position: absolute;
    left: 3%;
    cursor: pointer;
    width: 12%;
    top: 1.6em;
  }

  @media (min-width: 1024px) {
    width: 15%;
  }

  @media (min-width: 1200px) {
    width: 12%;
  } */
`;

export const CabecalhoBusca = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block;
    width: 100%;
  }
`;

export const CabecalhoBuscaLinha = styled.div`
  width: inherit;

  form {
    display: flex;
    width: 100%;

    #iptBusca {
      height: 35px;
      width: 100%;
      padding: 0 12px;
      border: 1px solid #fff;
      color: #727272;
      border-radius: 0;
      background-color: var(--barraPesquisa-bg-color);

      &:focus {
        outline: none;
      }
    }

    button {
      width: 30%;

      span {
        font-size: 16px;
        color: #fff;
      }
    }
  }
`;

export const CabecalhoUsuario = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  a {
    color: black;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;

export const CabecalhoCarrinho = styled.div`
  width: 50%;
  text-align: initial;
  a {
    color: #fff;
  }

  @media (max-width: 920px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;

export const Subtitulo = styled.span`
  @media (max-width: 920px) {
    display: none;
  }
`;

export const CabecalhoUsuarioDeslogado = styled.div`

.btn-shop{
  display: inline-flex;
  align-items: center;
  text-transform: none;
}

.infos-cart{
  display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 5px;
    text-align: initial;
}


  @media (min-width: 992px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const MenuDesktop = styled.div`
  @media (min-width: 1366px) {
    position: absolute; //ToDo: alterar para fixed quando implementar o scroll;
    width: 100%;
    background: var(--menuBackground-bg-color);
    top: 104px;
    display: flex;
    justify-content: space-around;
    margin: 0;
    left: 0;
  }

  @media (max-width: 1366px) {
    position: absolute; //ToDo: alterar para fixed quando implementar o scroll;
    width: 100%;
    background: var(--menuBackground-bg-color);
    top: 104px;
    display: flex;
    margin: 0;
    left: 0;
    justify-content: space-between;
  }

  @media (max-width: 726px) {
    position: relative;
    /* width: 100%; */
    background: var(--menuBackground-bg-color);
    /* top: 104px; */
    display: flex;
    justify-content: space-around;
    margin: 0;
    left: -2rem;
  }
`;

export const LinkMenu = styled(Link)`
  color: #f2f2f2;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  font-size: 17px;
  width: 100%;

  &:visited,
  &:focus,
  &:hover {
    background: #008c8c;
    opacity: 0.8;
  }
`;

/* MENU GERAL É O MENU HOME/ EDITORA ...  */
export const MenuGeral = styled.div` 
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

  .container {
    padding: 0 1rem;
    left: -1rem;
  }

  .usuarioLogado{
    color: #ffffff;
  }

  .log-sign {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex: 1;
  }

  .nav-btn {
    flex: 3;
    display: flex;
  }

  .btn {
    display: inline-block;
    padding: 0.5rem 1.3rem;
    font-size: 0.8rem;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 2rem;
    line-height: 1;
    margin: 0 0.2rem;
    transition: 0.3s;
    text-transform: uppercase;
  }

  /* DIREÇÃO DO DISPLAY */
  .nav-links > ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ALINHAMENTO SUBMENUS */
  .nav-link {
    position: relative;
  }

  /* DECORAÇÃO DOS MENUS E SUBMENUS */
  .nav-link > a {
    line-height: 3rem;
    color: var(--menuTextos-bg-color);
    padding: 0 0.8rem;
    letter-spacing: 1px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;
    max-width: 150px;
    min-width: 150px;

    @media (min-width: 1700px) and (max-width: 1855px) {
      min-width: 150px;
    }

    @media (max-width: 726px) {
      max-width: 100%;
      min-width: 100%;
      line-height: 2.5rem;

    }

    @media (max-width: 450px) {
      max-width: 100%;
      min-width: 100%;
      line-height: 2.3rem;

    }

    @media (max-width: 1366px){
      padding: 0 0.1rem;
    }

    @media (max-width: 1330px){
      padding: 0 0rem;
    }

    @media (max-width: 1300px){
      min-width: 145px;
    }
    
    @media (max-width: 1260px){
      min-width: 140px;
    }

    @media (max-width: 1220px){
      min-width: 135px;
    }
  }

  /* HOVER DOS MENUS */
  .nav-link > a:hover {
    background-color: var(--menuHover-bg-color);
    color: #fff;
  }

  .nav-link > a > i {
    margin-left: 2rem;
  }

  /* DROPDOWN */
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 115%;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
  }

  /* POSIÇÃO DO DROPDOWN */
  .dropdown ul {
    position: relative;
  }

  /* DECORAÇÃO DO DROPDOWN */

  .userSelecionado{
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
  }

  .dropdown-link > a {
    display: flex;
    background-color: var(--secondaryMenuBackground-bg-color);
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    border-bottom: 1px solid #efefef;

    @media (min-width: 920px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  /* HOVER DROPDOWN */
  .dropdown-link:hover > a {
    background-color: #313131;
    color: #fff;
  }

  .dropdown-link i {
    transform: rotate(-90deg);
  }

  /* POSIÇÃO DROPDOWN */
  .dropdown-link {
    position: relative;
  }

  /* POSIÇÃO DOS SUBMENUS */
  .dropdown.second {
    top: 0;
    left: 100%;
    padding-left: 0.8rem;
    cursor: pointer;
    transform: translateX(10px);
  }

  /* SETAS SEGUNDO MENU */
  .dropdown.second .arrow {
    top: 10px;
    left: -5.5px;
  }

  /* EXIBIR SUBMENUS */
  .nav-link:hover > .dropdown,
  .dropdown-link:hover > .dropdown {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
  }

  /* FICAR VISIVEL OU NÃO O HAMBURGUER  */
  .hamburguer-menu-container {
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
    margin-top: -6.5rem;
  }

  /* POSIÇÃO E TAMANHO DO HAMBURGUER */
  .hamburguer-menu {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: flex-end;
    top: 2rem;
  }

  /* EXIBIÇÃO DA DIV DO HAMBURGUER */
  .hamburguer-menu div {
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: var(--iconeMenuHamburger-bg-color);
    position: relative;
    z-index: 1001;
    transition: 0.5s;
  }

  /* EXIBIÇÃO DO HAMBURGUER COM AS SETAS */
  .hamburguer-menu div::before,
  .hamburguer-menu div::after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--iconeMenuHamburger-bg-color);
    border-radius: 3px;
    transition: 0.5s;
  }
  /* BEFORE E AFTER GIRANDO */
  .hamburguer-menu div:before {
    transform: translateY(-7px);
  }
  .hamburguer-menu div:after {
    transform: translateY(7px);
  }

  /* CHECKBOX */
  #check {
    position: absolute;
    bottom: 0;
    left: 22.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
  }

  #check:checked ~ .hamburguer-menu-container .hamburguer-menu div {
    background-color: transparent;
  }

  #check:checked ~ .hamburguer-menu-container .hamburguer-menu div::before {
    transform: translateY(0) rotate(-45deg);
  }

  #check:checked ~ .hamburguer-menu-container .hamburguer-menu div::after {
    transform: translateY(0) rotate(45deg);
  }

  @keyframes animation {
    from {
      opacity: 0;
      transform: translateY(15px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @media (min-width: 920px) {
    .log-sign {
      display: none;
    }
  }
  @media (max-width: 920px) {
    * {
      margin: 0;
      padding: 0px;
    }

    .container {
      position: absolute;
      left: -1rem;
      /* top: 1rem; */
    }

    .hamburguer-menu-container {
      display: flex;
      margin-left: 15px;
    }

    .hamburguer-menu div{
      top: 2rem;
    }

    #check {
      display: block;
      left: 2.5rem;
      top: -4.5rem;
    }

    .arrow {
      opacity: 0;
    }

    .nav-btn {
      max-width: 100%;
      position: fixed;
      height: calc(100vh - 3rem);
      top: 8.2rem;
      left: 0;
      width: 100%;
      background-color: var(--menuBackground-bg-color);
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      transform: translateX(100%);
      transition: 0.65rem;
      /* margin-top: 50px; */
    }

    #check:checked ~ .nav-btn {
      transform: translateX(0);
      z-index: 2;
    }

    #check:checked ~ .nav-btn .nav-link,
    #check:checked ~ .nav-btn .log-sign {
      animation: animation 0.5s ease forwards;
    }

    .nav-links {
      flex: initial;
      width: 100%;
      margin-left: 1rem;
    }

    .nav-links > ul {
      flex-direction: column;
    }

    .nav-link {
      width: 100%;
      opacity: 0;
      transform: translateY(15px);
    }

    .nav-links > a {
      line-height: 1;
      padding: 5px 10px;
    }

    .nav-link:hover > a {
      transform: scale(1);
      background-color: var(--menuHover-bg-color);
    }

    .dropdown,
    .dropdown.second {
      position: initial;
      top: initial;
      left: initial;
      transform: initial;
      opacity: 1;
      pointer-events: auto;
      width: 100%;
      padding: 0;
      display: none;
    }

    .nav-link:hover > .dropdown,
    .dropdown-link:hover > .dropdown {
      display: block;
    }

    .nav-link:hover > a > i,
    .dropdown-link:hover > a > i {
      transform: rotate(360deg);
    }

    .dropdown-link > a {
      color: #fff;
      padding: 1.2rem 2rem;
      line-height: 1;
    }

    .dropdown.second .dropdown-link > a {
      padding: 1.2rem 2rem 1.2rem 3rem;
    }

    .dropdown.second .dropdown.second .dropdown-link > a {
      padding: 1.2rem 2rem 1.2rem 4rem;
    }

    .dropdown-link:not(:nth-child(2)) {
      border-bottom: none;
    }
    
    .nav-link > a > i {
      font-size: 1.1rem;
      transform: rotate(-90deg);
      transition: 0.7s;
    }

    .dropdown i {
      font-size: 1rem;
      transition: 0.7s;
    }

    .log-sign {
      /* flex: initial; */
      width: 100%;
      padding: 10px;
      /* justify-content: center; */
      opacity: 0;
      transform: translateY(15px);
    }
  }
`;

export const Dropdow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  right: 4rem;
  /* z-index: 1; */
  @media (max-width: 923px) {
    right: 0;
    .nav-links > a{
      font-size: 8px;
      padding: 5px;

    }
    .usuarioLogado{
      font-size: 12px;
      margin-top: 2px;
    }
  }
  
  .area-usuario {
    border: solid 1px;
    width: 100%;
    background-color: var(--main-bg-color); //alterar
    top: 15px;
    position: relative;
    right: 11rem;
    top: 20px;
    box-shadow: 10px 10px 10px 0px rgba(133, 130, 130, 0.5);
    -webkit-box-shadow: 10px 10px 10px 0px rgba(133, 130, 130, 0.5);
    -moz-box-shadow: 10px 10px 10px 0px rgba(133, 130, 130, 0.5);
  }

  .iconUser {
    margin-right: 10px;
    /* color: #ffffff; */
  }

  .loginUsuario {
    color: var(--headerTextos-bg-color);
    z-index: -1;
  }
  
  .loginUsuario:hover {
    color: #111 //alterar;
  }

  .header-usuario {
    height: auto;
    border-bottom: solid 1px;
    border-color: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    color: black;
    align-items: flex-start;
    padding-bottom: 5px;
  }

  .editar-usuario {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .editUsuario{
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    justify-content:center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logoutUsuario{
    color: white;
  }

  .pedidos-usuario {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logout-usuario {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /* AREA USUÁRIO DEIXA HOVER NOS ICONS */
  .icons:hover,
  .links:hover,
  a:hover,
  .editar-usuario:hover {
    color: #423e3e;
  }

  p {
    margin: 0;
  }

  .divs-area-usuario {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
  }

  .nome-usuario {
    font-weight: bold;
    margin-bottom: 5px;
    margin-right: 3px;
    font-size: 18px;
  }

  .cargo-usuario {
    font-weight: bold;
    font-size: 13px;
  }

  .icons {
    display: flex;
    padding-right: 20px;
    width: 10px;
    cursor: pointer;
    color: black;
    text-align: center;
  }

  .links {
    display: flex;
    width: auto;
    cursor: pointer;
    color: black;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .dropdow-content {
    display: none;
    position: fixed;
    /* top: 1.8rem; */
    /* min-width: 160px; */
    padding: 10px;
    z-index: 2;
    right: 1rem;
  }

  .nomeCod {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .codUsuarioVersa {
    font-weight: bold;
  }

  &:hover .dropdow-content {
    display: block;
  }

  /* DIREÇÃO DO DISPLAY */
  .nav-links > ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ALINHAMENTO SUBMENUS */
  .nav-link {
    position: relative;
  }

  /* DECORAÇÃO DOS MENUS E SUBMENUS */
  .nav-link > a {
    color: #fff;
    padding: 5px;
    letter-spacing: 1px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;

  }
 

  /* HOVER DOS MENUS */
  .nav-link > a:hover {
    background-color: var(--menuHover-bg-color);
    color: #fff;
  }

  .nav-link > a > i {
    margin-left: 2rem;
  }

  /* DROPDOWN */
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }

  /* POSIÇÃO DO DROPDOWN */
  .dropdown ul {
    position: relative;
  }

  /* DECORAÇÃO DO DROPDOWN */
  .dropdown-link > a {
    display: flex;
    background-color: var(--menuBackground-bg-color);
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    border-bottom: 1px solid #efefef;
  }

  @media(max-width: 923px){
    .dropdown-link > a {
    border-bottom: none;
  }
  }

  /* HOVER DROPDOWN */
  .dropdown-link:hover > a {
    background-color: var(--menuHover-bg-color);
    color: #fff;
  }
`;
